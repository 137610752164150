<template>
  <div class="container-fluid p-5">
    <v-row dense v-if="loading">
      <v-col v-for="(item, index) in 6" :key="index" :cols="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <v-row dense v-if="news.length > 0">
        <v-col v-for="(item, index) in news" :key="index" :cols="4">
          <v-card class="mx-auto my-2" max-width="344">
            <a @click="goToNew(item.slug)">
              <v-img :src="item.thumbnail" height="200px"></v-img>
            </a>
            <v-card-title class="new-name"> {{ item.title }} </v-card-title>

            <v-card-subtitle>
              <em class="fas fa-eye mr-3 mt-4"> {{ item.views }}</em>
              visualizaciones
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="lighten-2" text @click="toggle(item)">
                Ver más
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="toggle(item)">
                <v-icon>{{
                  item.show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="item.show">
                <v-divider></v-divider>

                <v-card-text>
                  {{ item.description }}
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <div class="container" v-else>
        <div class="row">
          <div class="col-12 center-block text-center" style="height: 160px">
            <p style="font-size: 1.5em">No hay noticias recientes.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      news: [],
      loading: true,
      isDark: false,
    };
  },
  methods: {
    toggle: function (item) {
      item.show = !item.show;
    },
    goToNew(slug) {
      const config = {
        slug: slug,
      };
      this.$router.push({
        name: "noticia",
        params: config,
      });
    },
    async getNews() {
      // const { data } = await this.$axios.get(
      //   `http://crm_ipssoma.test/api/v1/public/news/list`
      // );
      const { data } = await this.$axios.get(`/public/news/list`);
      console.log(data)
      this.news = this.setShowFalse(data);
      this.loading = false;
    },

    setShowFalse(data) {
      data.forEach((item) => {
        item.show = false;
      });
      return data;
    },
  },
  mounted() {
    this.getNews();
  },
};
</script>
<style scoped>
.new-name {
  display: block; /* ayuda a aparecer los ... */
  text-align: left;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.theme--light.v-btn {
    color: #fc5203;
}
</style>
